<script>
import { _ } from 'svelte-i18n';

let daoIcon = 'images/dao-icon.svg';
let governanceIcon = 'images/governance-icon.svg';
let elasticityIcon = 'images/elastic-icon.svg';
</script>

<section class="what">
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column is-mobile">
        <h1 class="section-header">{$_('What.What_is_Elastic_DAO')}</h1>
        <p class="section-text">
          {$_('What.Inspired_by')}
          <span class="large">Moloch + Aragon + PieDAO + AAVE</span>
        </p>
      </div>
    </div>

    <div class="columns">
      <div class="column is-third">
        <img src="{daoIcon}" alt="inspired by moloch dao" />
        <p class="section-text">{$_('What.DAO')}</p>
        <p>
          {$_('What.Elastic_DAO_is_a_platform_for_deploying')}
          <u>{$_('What.fair_governance')}</u>
          {$_('What.As_well_as_a_DAO')}
        </p>
      </div>
      <div class="column is-third">
        <img src="{governanceIcon}" alt="inspired by moloch dao" />
        <p class="section-text">{$_('What.Governance')}</p>
        <p>{$_('What.Custom_governance_modules')}</p>
      </div>
      <div class="column is-third">
        <img src="{elasticityIcon}" alt="inspired by moloch dao" />
        <p class="section-text">{$_('What.Elasticity')}</p>
        <p>{$_('What.Elastic_supply_maintains')}</p>
      </div>
    </div>
  </div>
</section>
