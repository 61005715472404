const proposalMessage = {
  version: '0.1.3',
  timestamp: null,
  space: 'elasticdao.eth',
  type: 'proposal',
  payload: {
    name: null,
    body: null,
    choices: ['Yes', 'No', 'Abstain'],
    start: null,
    end: null,
    snapshot: null,
    metadata: {
      strategies: [
        {
          name: 'contract-call',
          params: {
            address: '0x2aa5ce395b00cc486159adbdd97c55b535cf2cf9',
            decimals: 18,
            symbol: 'EGT',
            args: ['%{address}'],
            methodABI: {
              inputs: [
                { internalType: 'address', name: '_account', type: 'address' },
              ],
              name: 'balanceOfVoting',
              outputs: [
                { internalType: 'uint256', name: 'balance', type: 'uint256' },
              ],
              stateMutability: 'view',
              type: 'function',
            },
          },
        },
      ],
    },
  },
};

const voteMessage = {
  payload: {
    choice: null,
    metadata: {},
    proposal: '', // proposal.id ipfsHash
  },
  space: 'elasticdao.eth',
  timestamp: null,
  type: 'vote',
  version: '0.1.3',
};

export { proposalMessage, voteMessage };
