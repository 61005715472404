import { isPOJO, isString } from '@pie-dao/utils';
import { push } from 'svelte-spa-router';
import qs from 'qs';

import {
  DashboardTabs,
  MembershipTabs,
  OrganizationSettingsTabs,
  Paths,
  VoteFilters,
} from './constants';

export const go = (...routeParts) => push(path(...routeParts));
export const path = (...routeParts) => {
  const query = routeParts.find(isPOJO);
  const validParts = routeParts.filter(isString);
  const partsToJoin = [validParts[0], validParts[1], validParts[2]].filter(
    isString,
  );
  const route = `/${partsToJoin.join('/')}`;
  if (isPOJO(query)) {
    return `${route}?${qs.stringify(query)}`;
  }
  return route;
};

export const changeDashboardTab = (id, tab) => {
  if (Object.values(DashboardTabs).includes(tab)) {
    if (tab === DashboardTabs.Modules) {
      go(Paths.Modules, id);
    } else if (tab === DashboardTabs.Voting) {
      go(Paths.Voting, id);
    } else {
      go(Paths.Dashboard, id, tab);
    }
  } else {
    go(Paths.NotFound);
  }
};

export const changeMembershipTab = (id, tab) => {
  if (Object.values(MembershipTabs).includes(tab)) {
    if (
      tab === MembershipTabs.Join ||
      tab === MembershipTabs.JoinSelect ||
      tab === MembershipTabs.Exit
    ) {
      go(Paths.Membership, id, tab);
    }
  }
};

export const changeOrganizationSettingsTab = (id, tab) => {
  if (Object.values(OrganizationSettingsTabs).includes(tab)) {
    go(Paths.OrganizationSettings, id, tab);
  } else {
    go(Paths.NotFound);
  }
};

export const changeVoteFilter = (id, filter) => {
  if (Object.values(VoteFilters).includes(filter)) {
    go(Paths.Voting, id, filter);
  } else {
    go(Paths.NotFound);
  }
};
