<script>
import { changeMembershipTab } from '../../../routes/index.js';
import { MembershipTabs } from '../../../routes/constants.js';

const join = 'images/join.svg';
const sushiImage = 'images/sushiswap.png';

const openSushi = () => {
  window.open(
    `https://app.sushi.com/swap/0x2aa5ce395b00cc486159adbdd97c55b535cf2cf9`,
  );
};

export let params = {};
</script>

<section class="section fade-in">
  <div class="section-header">
    <h1>How would you like to Join?</h1>
  </div>

  <div class="columns">
    <div class="column is-mobile is-clickable">
      <div
        class="panel has-text-centered"
        on:click="{() => changeMembershipTab(params.id, MembershipTabs.Join)}"
      >
        <img src="{join}" alt="tokens" />
        <p>Mint maximum voting power</p>
      </div>
    </div>

    <div class="column is-mobile is-clickable">
      <div
        class="panel has-text-centered"
        on:click|preventDefault|stopPropagation="{() => {
          openSushi();
        }}"
      >
        <div class="sushi-wrapper">
          <img class="sushi-join" src="{sushiImage}" alt="tokens" />
        </div>
        <p>Buy on Sushi.com</p>
      </div>
    </div>
  </div>
</section>
