<script>
import { go, Paths } from '../../routes/index.js';
import { _ } from 'svelte-i18n';

const image = './images/jelly-fish-bubbles.svg';
</script>

<div class="landing-hero">
  <section class="hero">
    <div class="container is-max-desktop">
      <div class="columns call-to-action">
        <div class="column is-half">
          <h1>{$_('Hero.Hello_I_am')} <span>ElasticDAO</span></h1>
          <p class="call-to-action-text">
            {$_('Hero.I_am_an_experiment_in')}
            <u> {$_('Hero.fair_governance')}</u>.
            {$_('Hero.If_I_succeed')}
          </p>
          <button
            on:click="{() => go(Paths.Dashboard, '0xaaa1f5fc9617195b5aa7fd1bd989d47f9e8d3f82')}"
            class="call-to-action-button is-transparent"
          >
            {$_('Hero.browse_or_create_organizations')}
          </button>
        </div>
        <div class="column graph is-hidden-mobile">
          <img src="{image}" alt="elastic mascot" />
        </div>
      </div>
    </div>
  </section>
</div>
