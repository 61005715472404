<script>
export let params = {};
import { capitalDelta, deltaE, isDAO, mDash, t } from '@elastic-dao/sdk';

import Card from '../../shared/Card.svelte';

import { amountFormatter } from '../../../utils.js';
import { balances, key } from '../../../stores/balances.js';
import { changeDashboardTab, DashboardTabs } from '../../../routes/index.js';
import { connectWeb3, eth } from '../../../subeth';
import { liveOrganizations } from '../../../stores/organizations.js';

let slippage = 2;
let joining = false;
let joiningDisabled = true;
let hasEnoughToMint = true;

const { keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];

/* MATHS */

$: daoETHBalance = $balances[key($dao.uuid)];

$: entryPoints = ((
  { elasticity, k, lambda, m, maxLambdaPurchase },
  ethInDAO,
  iterations,
) => {
  const deltaLambda = maxLambdaPurchase;
  const entries = [];

  if (!ethInDAO) {
    joiningDisabled = true;
    return entries;
  }

  joiningDisabled = false;

  const calculateEntry = (e, lambda, m) => {
    const lambdaDash = lambda.plus(deltaLambda);
    const tValue = t(lambda, m, k);
    const capitalDeltaValue = capitalDelta(e, tValue);
    const deltaEValue = deltaE(
      deltaLambda,
      capitalDeltaValue,
      k,
      elasticity,
      lambda,
      m,
    );
    const mDashValue = mDash(lambdaDash, lambda, m);
    const deltaT = t(deltaLambda, mDashValue, k);
    const safeDeltaE = deltaEValue.multipliedBy(1.001);

    const result = {
      deltaEValue,
      deltaT,
      lambdaDash,
      mDashValue,
      safeDeltaE,
    };

    return result;
  };

  entries.push(calculateEntry(ethInDAO, lambda, m));

  let nextE = ethInDAO;

  for (let i = 1; i < iterations; i += 1) {
    const lastEntry = entries[i - 1];
    nextE = nextE.plus(lastEntry.deltaEValue);
    entries.push(
      calculateEntry(nextE, lastEntry.lambdaDash, lastEntry.mDashValue),
    );
  }

  return entries;
})($token, daoETHBalance, 4);

/* END MATHS */

/* ACTION */
const join = async () => {
  if (!$eth.signer) {
    await connectWeb3();
    const pid = setInterval(() => {
      if ($eth.signer) {
        clearInterval(pid);
        join();
      }
    }, 100);
    return;
  }

  joining = true;

  const response = await dao.elasticDAO
    .join({ value: entryPoints[slippage].safeDeltaE })
    .catch((e) => {
      joining = false;

      if (e.message.includes('insufficient funds')) {
        hasEnoughToMint = false;
      }
    });

  joining = false;
  if (isDAO(response)) {
    changeDashboardTab(params.id, DashboardTabs.Finances);
  }
};

const changeSlippage = async (slippageAmount) => {
  slippage = slippageAmount;
};

const getSlippageHeader = (index) => {
  switch (index) {
    case 0:
      return 'current price';
    case 1:
      return 'low';
    case 2:
      return 'medium';
    case 3:
      return 'high';
  }
};
</script>

<section class="join section fade-in">
  <div class="section-header">
    <h1>Join/Mint {$dao.name} ({$token.symbol})</h1>

    {#if !hasEnoughToMint}
      <div class="enough-to-mint">
        <p>We're sorry you do not have enough ETH to mint new voting power.</p>

        <p class="mt-0">
          You can find any amount of EGT on
          <a
            href="https://app.sushi.com/swap/{token.uuid}"
            target="_blank"
          >Sushi.com</a>
        </p>
      </div>
    {/if}

    <button
      class="button action-button"
      on:click="{join}"
      disabled="{joiningDisabled}"
    >
      <i
        class="{joining ? 'fas fa-spinner fa-spin mr-3' : 'fab fa-ethereum mr-3'}"
      ></i>
      join/mint
    </button>
  </div>

  <div class="columns is-multiline slide-controller">
    <div class="column is-full is-mobile">
      <h2>Select Your Slippage Tolerance:</h2>
      <p>
        If someone joins at the same time as you, it could cause your
        transaction to fail. We recommend setting your slippage in case you
        don't join in the next immediate position. This will save you gas, you
        will always get the best offer, and any unused funds will be returned.
      </p>
    </div>

    {#each entryPoints as entry, index}
      <div
        class="{slippage === index ? 'column is-one-quarter is-mobile active slippage' : 'column is-one-quarter is-mobile slippage'}"
        on:click="{() => changeSlippage(index)}"
      >
        <Card cardTitle="" identicon="{false}" noZoom="{true}">
          <div class="current-price mb-3">
            <p>{getSlippageHeader(index)}</p>
          </div>

          <div class="active-check"><i class="fas fa-check-circle"></i></div>

          <div class="card-data">
            <div>
              <h3>Cost</h3>
              <h4>ETH</h4>
              <p>
                {#if index > 0}
                  {amountFormatter({
                    amount: entryPoints[0].safeDeltaE,
                    approximatePrefix: '',
                  })}
                  <br /><span>up to</span><br />
                {/if}
                {amountFormatter({
                  amount: entry.safeDeltaE,
                  approximatePrefix: '',
                })}
              </p>
            </div>

            <div>
              <h3>Receive</h3>
              <h4>{$token.symbol}</h4>
              <p>
                {#if index > 0}
                  {amountFormatter({
                    amount: entryPoints[0].deltaT,
                    decimalPlaces: 0,
                    rounding: 4,
                    approximatePrefix: '',
                  })}
                  <br /><span>up to</span><br />
                {/if}
                {amountFormatter({
                  amount: entry.deltaT,
                  decimalPlaces: 0,
                  rounding: 4,
                  approximatePrefix: '',
                })}
              </p>
            </div>
          </div>
        </Card>
      </div>
    {/each}
  </div>
</section>
