/* eslint no-unused-expressions: 0 */

import GnosisMultisig from './GnosisMultisig';

const ApiUrl = 'https://safe-transaction.mainnet.gnosis.io/api/v1/';

export class GnosisSafeAPI {
  constructor(url, address, daoAddress) {
    this._address = address;
    this._daoAddress = daoAddress;
    this._multisig = {};
    this._url = url;
    this.load();
  }

  get address() {
    return this._address;
  }

  get daoAddress() {
    return this._daoAddress;
  }

  get multisig() {
    return this._multisig;
  }

  get url() {
    return this._url;
  }

  async getMultisig() {
    const url = `${this.url}/safes/${this.address}`;
    const response = await fetch(url);
    const json = await response.json();
    return new GnosisMultisig(this, json);
  }

  async load() {
    this._multisig = await this.getMultisig();
  }

  subscribe(callback) {
    callback(this);
    this._callback = (obj) => {
      console.log('subscribe callback fired');
      callback(obj);
    };
    return () => {
      console.log('unsubscribe callback fired');
      this._callback = undefined;
    };
  }

  touch() {
    this._callback && this._callback(this);
  }
}

export const gnosisSafeAPI = new GnosisSafeAPI(
  ApiUrl,
  '0x8C2251e028043e38f58Ac64c00E1F940D305Aa62',
  '0xaaa1f5fc9617195b5aa7fd1bd989d47f9e8d3f82',
);
