<script>
import { go, Paths } from '../routes/index.js';
import { connectWeb3, disconnectWeb3, eth } from '../subeth';
import { _ } from 'svelte-i18n';

export let params = {};

let logo = 'images/edao.svg';
let showMobileMenu = false;

const toggleMobileMenu = () => {
  showMobileMenu = !showMobileMenu;
};
</script>

<nav
  class="navbar is-transparent"
  role="navigation"
  aria-label="main navigation"
>
  <div class="container navbar-container">
    <div
      class="navbar-brand is-clickable"
      on:click="{() => ($eth.address ? go(Paths.Client) : go())}"
    >
      <div class="logo-container">
        <div class="logo-circle">
          <img src="{logo}" alt="elastic dao logo" />
        </div>

        <span class="logo-text bold">Elastic</span>
        <span class="logo-text">DAO</span>
        <span class="highlight beta-tag">BETA</span>
      </div>
    </div>

    <div class="navbar-end">
      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        on:click="{() => toggleMobileMenu()}"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <div class="{showMobileMenu ? 'navbar-menu is-active' : 'navbar-menu'}">
        <div class="navbar-item">
          <span
            class="block-number"
          >{`Current Block: #${$eth.currentBlockNumber}`}</span>
        </div>

        <div class="navbar-item">
          {#if $eth.address}
            <button
              class="unlock is-transparent"
              on:click="{() => disconnectWeb3()}"
            >
              <span class="identicon">
                {@html $eth.icon}
              </span>
              {$eth.shortAddress}
            </button>
          {:else}
            <button
              class="unlock is-transparent"
              on:click="{() => connectWeb3()}"
            >
              {$_('Header.connect_account')}
            </button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</nav>
