<script>
export let params = {};
export let sdk = {};

// imports
import { _ } from 'svelte-i18n';
import moment from 'moment';
import showdown from 'showdown';
import Bar from 'svelte-chartjs/src/HorizontalBar.svelte';

import { eth } from '../../../../subeth/index.js';
import { gnosisSafeAPI } from '../../../../multisig';
import { go, VoteFilters } from '../../../../routes/index.js';
import { shortenAddress } from '@pie-dao/utils';
import { snapshotAPI, publish } from '../../../../snapshot';
import { truncate, amountFormatter } from '../../../../utils.js';
import { voteMessage } from '../../../../snapshot/message.js';
import AddressBar from '../../../shared/AddressBar.svelte';
import Card from '../../../shared/Card.svelte';

Chart.defaults.global.legend.display = false;

const markdown = new showdown.Converter({
  openLinksInNewWindow: true,
  simplifiedAutoLink: true,
});

// images
const vote = 'images/vote-large.svg';

let proposalsFilter = 'all';

$: filteredProposals = proposalsFilter === 'all' ? $snapshotAPI.proposals : $snapshotAPI.proposals.filter((proposal) => proposal.status === proposalsFilter);

let filterVotes = false;
let publishing = false;
let voting = { active: false, id: null };
let voteExpanded = {};
let viewingResults = [];
let voteNotification = false;

const publishVote = async (proposal, choice) => {
  // 1 = Yes
  // 2 = No
  // 3 = Abstain
  if (choice > 3 || choice < 1) {
    console.error(
      'error',
      'ElasticDAO: Not a valid vote choice(1: Yes, 2: No, 3: Abstain)',
    );
    return;
  }

  voting = { active: true, id: proposal.id };

  let msg = voteMessage;
  msg.timestamp = Math.floor(new Date().getTime() / 1000).toString();
  msg.payload.choice = choice;
  msg.payload.proposal = proposal.id;
  const signedMessage = await $eth.signer.signMessage(JSON.stringify(msg)).catch(() => (voting = { active: false, id: null }));

  await publish({
    address: $eth.address,
    msg: JSON.stringify(msg),
    sig: signedMessage,
  }).catch((e) => {
    voting = { active: false, id: null };
  });

  voting = { active: false, id: null };
  toggleVoteNotification();
  proposal.getVotes();
};

const toggleExpansion = (voteId) => {
  voteExpanded[voteId] = !voteExpanded[voteId];
};

const toggleResultsModal = (index) => {
  viewingResults[index] = !viewingResults[index];
}

const toggleVoteNotification = () => {
  voteNotification = !voteNotification;
}
</script>

<section class="section fade-in">
  <div class="section-header">
    <h1>{$_('Dashboard.Snapshot_Votes')}</h1>

    <button
      class="button action-button"
      on:click="{() => go(...params.path, { action: 'new' })}"
    >
      <i
        class="{publishing ? 'fas fa-spinner fa-spin mr-3' : 'fas fa-poll mr-3'}"
      ></i>{$_('Dashboard.new_proposal')}
    </button>
  </div>

  <Card
    cardTitle=""
    identicon="{false}"
    noZoom="{true}"
    noMinHeight={true}
  >
    <p>
      <i class="fas fa-info-circle" />
      If your vote does not appear it is because you did not have any voting power at the block
      the snapshot was taken.
    </p>
  </Card>

  <div class="filters">
    <div
      class="filter {proposalsFilter === 'all' && 'is-active'}"
      on:click="{() => proposalsFilter = VoteFilters.All}"
    >
      {$_('Dashboard.All')}
    </div>

    <div
      class="filter {proposalsFilter === 'active' && 'is-active'}"
      on:click="{() => proposalsFilter = VoteFilters.Active}"
    >
      {$_('Dashboard.Active')}
    </div>
    <div
      class="filter {proposalsFilter === 'pending' && 'is-active'}"
      on:click="{() => proposalsFilter = VoteFilters.Pending}"
    >
      {$_('Dashboard.Pending')}
    </div>
    <div
      class="filter {proposalsFilter === 'closed' && 'is-active'}"
      on:click="{() => proposalsFilter = VoteFilters.Closed}"
    >
      {$_('Dashboard.Closed')}
    </div>
  </div>

  {#if filteredProposals.length === 0}
    <div class="has-text-centered mt-8">
      <img class="mt-6" src="{vote}" alt="no votes created yet" />
      <h2>
        {$_('Dashboard.There_have_not_been_any_informational_votes_yet')}
      </h2>
    </div>
  {:else}
    <div class="votes">
      {#each filteredProposals as proposal, index}
        <div class={voteNotification ? "modal is-active fade-in" : "modal"}>
          <div class="modal-background"></div>
          <div class="modal-content">
            <img src={vote} alt="vote success" />

            <h1>Thank you for participating in Fair Governance!</h1>
          </div>
          <button
            class="modal-close is-large"
            aria-label="close"
            on:click|preventDefault|stopPropagation={() => toggleVoteNotification()}>
          </button>
        </div>

        <div
          id="{proposal.id}"
          class="vote is-clickable"
          on:click="{() => toggleExpansion(proposal.id)}"
        >
          <div class="columns">
            <div class="column pb-0">
              <div class="vote-status">
                <div class="mr-3 pill {proposal.status}">
                  {#if voting.active === true && voting.id === proposal.id}
                    <i class="fas fa-spinner fa-spin mr-3" />
                  {/if}
                  {proposal.status}
                </div>
                <div class="vote-info">
                  <p>
                    <a
                      href="{`https://cloudflare-ipfs.com/ipfs/${proposal.id}`}"
                      target="_blank"
                      rel="noopener nofollow"
                    >
                      {truncate(proposal.id, { length: 10 })}
                    </a>:
                    {truncate(proposal.name, { length: 300 })}
                  </p>
                  <p class="vote-created-by">
                    {$_('Dashboard.Created_By')}:
                    <a
                      target="_blank"
                      rel="noopener nofollow"
                      href="{`https://etherscan.com/address/${proposal.author}`}"
                    >{shortenAddress(proposal.author)}</a>
                    {#if proposal.didVote($eth.address)}
                    - You voted:
                    <a
                      href="{`https://cloudflare-ipfs.com/ipfs/${proposal.myVote($eth.address).id}`}"
                      target="_blank"
                      rel="noopener nofollow"
                    >
                      {proposal.myVote($eth.address).choice}
                    </a>
                    {/if}
                  </p>
                </div>
              </div>
            </div>
            <div>
              {#if voteExpanded[proposal.id]}
              <i class="far fa-chevron-double-up"></i>
              {:else}
              <i class="far fa-chevron-double-down"></i>
              {/if}
            </div>
          </div>

          <div
            class="{voteExpanded[proposal.id] ? 'vote-content expanded' : 'vote-content'}"
          >
            <div class="columns is-multiline">
              <div class="column is-full">
                <h5>{$_('Dashboard.Proposal')}</h5>
                <p>
                  {@html markdown.makeHtml(proposal.body)}
                </p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('Dashboard.Start_Date')}</h5>
                <p>
                  {new Date(proposal.start * 1000).toLocaleDateString()}
                  {#if proposal.status === VoteFilters.Pending}
                    <span
                    >({moment
                        .unix(proposal.start)
                        .fromNow()})</span>
                  {/if}
                </p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('Dashboard.End_Date')}</h5>
                <p>
                  {new Date(proposal.end * 1000).toLocaleDateString()}
                  <span
                    class="{proposal.status === VoteFilters.Closed ? 'highlight' : ''}"
                  >({moment.unix(proposal.end).fromNow()})</span>
                </p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('NewVote.Snapshot_Block')}</h5>
                <p>{proposal.snapshot}</p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>Quorum Reached</h5>
                <p>
                  {proposal.quorum > 50 ?
                  `Yes(${amountFormatter({ amount: proposal.quorum })}%)` :
                  `No(${amountFormatter({ amount: proposal.quorum })}%)`}
                </p>
              </div>
            </div>

            <div class="columns is-multiline mt-5">
              <div class="column is-flex is-justify-content-left is-one-half">
                <div>
                  <div>
                    <h5 class="is-full">Vote Actions</h5>
                    <button
                      on:click|stopPropagation="{() => publishVote(proposal, 1)}"
                      class="button action-button yes mr-5"
                      disabled="{!$eth.address || proposal.status !== VoteFilters.Active}"
                    >
                      <i class="fas fa-vote-yea mr-3"></i>
                      {$_('Dashboard.Yes')}
                    </button>
                    <button
                      on:click|stopPropagation="{() => publishVote(proposal, 2)}"
                      class="button action-button no mr-5"
                      disabled="{!$eth.address || proposal.status !== VoteFilters.Active}"
                    >
                      <i class="fas fa-vote-nay mr-3"></i>
                      {$_('Dashboard.No')}
                      </button>
                    <button
                      on:click|stopPropagation="{() => publishVote(proposal, 3)}"
                      class="button action-button abstain mr-5"
                      disabled="{!$eth.address || proposal.status !== VoteFilters.Active}"
                    >
                      <i class="fas fa-vote-ballot mr-3"></i>
                      {$_('Dashboard.Abstain')}
                    </button>
                  </div>

                  <div class="mt-5">
                    <h5 class="is-full">Vote Results</h5>
                    <button
                    on:click|preventDefault|stopPropagation={() => toggleResultsModal(index)}
                      class="button action-button mr-5"
                      disabled="{proposal.votes.length === 0}"
                    >
                      <i class="fas fa-archive mr-3"></i>
                      Full Results
                    </button>
                  </div>

                  <div class="mt-5">
                    {#if proposal.didVote($eth.address)}
                    <h5>You voted: {proposal.myVote($eth.address).choice}</h5> 
                    {/if}
                  </div>
                </div>
              </div>

              <div class="is-flex is-align-items-flex-start is-flex-direction-column column is-one-half is-mobile">
                <h5 class="has-text-centered">Vote Tally</h5>
                <Bar
                  data={{
                    labels: ['Yes', 'No', 'Abstain'],
                    datasets: [{
                      data: [proposal.yes.toNumber(), proposal.no.toNumber(), proposal.abstain.toNumber()],
                      backgroundColor: ['#36bc8c', '#e0719e', '#5f69ef'],
                      borderColor: 'transparent',
                      borderWidth: 1,
                    }],
                  }}
                  options={{
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          try {
                            let label = ' ' + data.labels[tooltipItem.index] || '';

                            if (label) {
                              label += ': ';
                            }

                            const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                              return accumulator + curValue;
                            });
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                            label += Number((value / sum) * 100).toFixed(2) + '%';
                            return label;
                          } catch (error) {
                            console.log(error);
                          }
                        }
                      }
                    },
                    scales: {
                      xAxes: [{
                        gridLines: {
                          color: '#5957c9'
                        },
                        ticks: {
                          fontColor: '#fff'

                        }
                      }],
                      yAxes: [{
                        stacked: true,
                        gridLines: {
                          color: '#5957c9'
                        },
                        ticks: {
                          display: false
                        }
                      }]
                    }
                  }}
                />
              </div>
            </div>

            {#if proposal.votes.length > 0}
              <div class={viewingResults[index] ? "modal is-active" : "modal"} on:click|stopPropagation|preventDefault>
                <div class="modal-background"></div>
                <div class="modal-card">
                  <header class="modal-card-head">
                    <p class="modal-card-title">
                      {proposal.name}
                      {#if $eth.address}
                        <span class="highlight" on:click={() => filterVotes = !filterVotes}>
                          {!filterVotes ? '(Show My Vote)' : '(Show All Votes)'}
                        </span>
                      {/if}
                    </p>
                    <button class="delete" aria-label="close" on:click|stopPropagation|preventDefault={() => toggleResultsModal(index)}></button>
                  </header>
                  <section class="modal-card-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>
                            <abbr title="Voter">{$_('Dashboard.Voter')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Result"
                            >{$_('Dashboard.Vote_Result')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Voting Power"
                            >{$_('Dashboard.Votable_Shares')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Receipt"
                            >{$_('Dashboard.Receipt')}</abbr>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {#each (filterVotes && $eth.address ? [proposal.votes.find((vote) => vote.voter === $eth.address.toLowerCase())] : proposal.votes) as vote}
                          {#if vote}
                            <tr>
                              <td
                                class="is-flex is-justify-content-center is-clickable"
                              >
                                <a
                                  on:click|stopPropagation
                                  href="{`https://etherscan.com/address/${vote.voter}`}"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <AddressBar
                                    address="{vote.voter}"
                                    highlight={$eth.address && vote.voter === $eth.address.toLowerCase()}
                                  />
                                </a>
                              </td>
                              <td>
                                {vote.choice}
                              </td>
                              <td>{amountFormatter({ amount: vote.weight})}</td>
                              <td>
                                <a
                                  on:click|stopPropagation
                                  href="{`https://cloudflare-ipfs.com/ipfs/${vote.id}`}"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i class="fas fa-passport" title="relayer"></i>
                                </a>
                                <a
                                  on:click|stopPropagation
                                  href="{`https://cloudflare-ipfs.com/ipfs/${vote.author}`}"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i class="fas fa-receipt" title="author"></i>
                                </a>
                              </td>
                            </tr>
                          {:else}
                            <tr>No vote found</tr>
                          {/if}
                        {/each}
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</section>
