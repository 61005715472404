import { defaultProvider } from '../subeth';
import { get } from '../ipfs';

const cache = {};

export const getIndex = async () => {
  let indexHash;
  indexHash = cache.indexHash;

  if (!indexHash) {
    const elasticvote = await defaultProvider.getResolver('elasticvote.eth');
    cache.indexHash = (await elasticvote.getContentHash()).replace(
      'ipfs://',
      '',
    ); // ipfs hash
    indexHash = cache.indexHash;
    setTimeout(() => {
      cache.indexHash = undefined;
    }, 60000); // ttl 60s
  }

  if (cache[indexHash]) {
    return cache[indexHash];
  }

  console.log('index hash', indexHash);

  const raw = await get(indexHash);
  cache[indexHash] = JSON.parse(raw);

  return cache[indexHash];
};

export const ipfsSnapshot = async (blockNumber, fuzzyMatch = true) => {
  const index = await getIndex();

  let closestBlock = blockNumber;
  if (fuzzyMatch) {
    closestBlock =
      index.blocks.list.reverse().find((block) => block <= blockNumber) ||
      index.blocks.list[0];
  }

  const block = index.blocks[`${closestBlock}`];

  if (!block) {
    return undefined;
  }

  if (cache[`${closestBlock}`]) {
    return cache[`${closestBlock}`];
  }

  const [balancesRaw, statsRaw] = await Promise.all([
    get(block.hash, block.balances),
    get(block.hash, block.stats),
  ]);

  cache[`${closestBlock}`] = {
    block,
    balances: JSON.parse(balancesRaw),
    stats: JSON.parse(statsRaw),
  };
  return cache[`${closestBlock}`];
};
