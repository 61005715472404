<script>
import { etherscanLink } from '@pie-dao/utils';
import { lambdaFromT } from '@elastic-dao/sdk';
import { snapshotAPI } from '../../../../snapshot';
import { gnosisSafeAPI } from '../../../../multisig';
import BigNumber from 'bignumber.js';

import { liveOrganizations } from '../../../../stores/organizations.js';

export let params = {};

const { keys } = liveOrganizations;
$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];

let selectedProposals = [];
let selectedAddresses = [];
let sybilSuspects = [];
let sybilChecking = false;
let writingTransactions = false;

const toggleProposal = (id) => {
  let newProposals = selectedProposals.filter((proposalId) => proposalId !== id);
  if (!selectedProposals.includes(id)) {
    newProposals.push(id);
  }
  selectedProposals = newProposals;
}

$: eligibleProposals = snapshotAPI.proposals.filter((proposal) => proposal.status === 'closed');

$: rewardees = ((proposalIds) => {
  const wallets = {};
  
  eligibleProposals.forEach((proposal) => {
    if (!proposalIds.includes(proposal.id)) {
      return;
    }

    proposal.votes.forEach((vote) => {
      wallets[vote.voter] ||= BigNumber(0);
      wallets[vote.voter] = wallets[vote.voter].plus(vote.weight);
    });
  });

  const addresses = [];
  const potentialAddresses = Object.keys(wallets);

  for (let i = 0; i < potentialAddresses.length; i += 1) {
    const address = potentialAddresses[i];
    const voted = wallets[address];
    const reward = voted.multipliedBy(0.05);

    if (reward.isGreaterThan(0)) {
      addresses.push({
        address,
        reward,
        voted,
      });
    };
  }

  return addresses;
})(selectedProposals);

const selectAll = (evt) => {
  evt.preventDefault();
  selectedAddresses = rewardees.map(({address}) => address);
};

const selectNone = (evt) => {
  evt.preventDefault();
  selectedAddresses = [];
};

const sybilCheck = async () => {
  sybilChecking = true;
  const suspects = new Set();

  const results = await dao.elasticGovernanceToken.contract.queryFilter(
    'Transfer',
    12056928, // block dao was summoned on
  );

  console.log('checking', results.length, 'transfer records');

  for(let i = 0; i < results.length; i += 1) {
    const { args } = results[i];
    const from = args.from.toLowerCase();
    const to = args.to.toLowerCase();

    if (selectedAddresses.includes(from) && selectedAddresses.includes(to)) {
      suspects.add(from);
      suspects.add(to);
    }
  }

  sybilSuspects = Array.from(suspects);
  sybilChecking = false;
};

const writeTransactions = async () => {
  writingTransactions = true;
  const rewardData = {};
  const filtered = rewardees.filter(({ address }) => selectedAddresses.includes(address))
  for (let i = 0; i < filtered.length; i += 1) {
    const { address, reward } = filtered[i];
    rewardData[address] = lambdaFromT(reward, token.k, token.m);
  }
  await gnosisSafeAPI.multisig.buildRewardTransactions(rewardData);
  writingTransactions = false;
}

</script>

<div class="tokens">
  <div class="section">
    <div class="section-header">
      <h1>Select from the following votes</h1>
      {#each eligibleProposals as proposal}
      <div style="margin-bottom: 1rem;">
        <button class="button action-button" on:click="{() => {toggleProposal(proposal.id)}}">{proposal.name}</button>
      </div>
      {/each}
      <h2>Selected: {selectedProposals.join(', ')}</h2>    
      {#if selectedAddresses.length > 0}
      <div style="margin-bottom: 1rem;">
        <button class="button action-button" on:click="{() => {sybilCheck()}}">
          {#if sybilChecking}
          Checking...
          {:else}
          Sybil Check
          {/if}
        </button>
      </div>

      <div style="margin-bottom: 1rem;">
        <button class="button action-button" on:click="{() => {writeTransactions()}}">
          {#if writingTransactions}
          Writing...
          {:else}
          Write Transactions To Multisig
          {/if}
        </button>
      </div>
      {/if}
    </div>
  </div>
</div>

<div class="finance">
  <table class="table">
    <thead>
      <tr>
        <th>
          Reward?
          (<a href="/" on:click={selectAll}>all</a> |
          <a href="/" on:click={selectNone}>none</a>)
        </th>
        <th>Address</th>
        <th>Voted Amount</th>
        <th>Reward Amount</th>
      </tr>
    </thead>
    <tbody>
      {#each rewardees as {address, voted, reward}}
      <tr
        style="{sybilSuspects.includes(address) ? 'border: 1px solid red' : ''}"
      >
        <th><input type="checkbox" bind:group={selectedAddresses} value={address} /></th>
        <th><a href="{etherscanLink({ address, networkId: 1 })}" target="_blank">{address}</a></th>
        <th>{voted.toFixed(3)}</th>
        <th>{reward.toFixed(3)}</th>
      </tr>
      {/each}
    </tbody>
  </table>
</div>