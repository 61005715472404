<script>
import * as jdenticon from 'jdenticon';
import { truncate } from '../../utils.js';

export let cardTitle;
export let identicon = true;
export let noZoom = false;
export let noMinHeight = false;

import classNames from 'classnames';

const cardClass = classNames('card is-clickable', {
  'no-zoom': noZoom,
  'no-min-height': noMinHeight,
});
</script>

<div class="{cardClass}">
  {#if identicon}
    {@html jdenticon.toSvg(cardTitle, 60)}
  {/if}
  {#if cardTitle}
    <div class="card-title module">
      <span>{truncate(cardTitle, { length: 25 })}</span>
    </div>
  {/if}

  <slot />
</div>
