/* eslint no-unused-expressions: 0 */

import SnapshotProposal from './SnapshotProposal';
import SnapshotVote from './SnapshotVote';

const ApiUrl = 'https://hub.snapshot.page/api';

// proposals we don't want to show because ipfs is immutable.....
const ProposalsToFilter = [
  'QmeyUPyn41VnqAmFg2nsuKMAfFgr3YEZhXaiKENQYqSxhg',
  'QmVzxVWeE7hQKxW8mvqu5wVUDtS1KeYiLhCkPJUBkkW2tS',
  'QmWX9iLapic4ZR8V9CKFVZYRcGJkjsg2543DA6WpRPoUXa',
  'Qme7qpBPDhDLe1fUS2RmMQxKgaD3AJS6BV8xYiNYeh65ai',
  'QmV3LBzYawDig9nXpCdRKFDa3D8a5B9xyyXrChM8GR1F8M',
  'QmTBvPQickWCjYRFssQmCwKRjUFp1R1geaPZ3auENF7bcj',
  'QmXb6ugsNcpYeRSbSgLGXsqPWLxpW3YS4jzUD8xBzHtSzK',
  'QmUb8nUevErwgkfWHGTJgx3LbG1JQPtBD6GY94tUNqhHEq',
  'QmRVxJz2XvcWrL442t6ci3J7UA3zLYBN4m9Q3oPeBaeNBh',
  'QmX5kpCfKEGgjivrSTdbhdhjxWmj1Xo7ujeQVPv2SkeXJh',
  'QmSxYgaYPqHmuisA7Hgfdb1BcKufjmiPmwJJj5L1hyzS1D',
  'Qmb9BdVjUgtTiACeN8b63MXXRf1sG3FhxcwpWqAAqYog2u',
];

export class SnapshotAPI {
  constructor(url, space, proposalsToFilter) {
    this._space = space;
    this._url = url;
    this._proposals = [];
    this._proposalsToFilter = proposalsToFilter;
    this.load();
  }

  get proposals() {
    return this._proposals;
  }

  get space() {
    return this._space;
  }

  get url() {
    return this._url;
  }

  async getProposals() {
    const url = `${this.url}/${this.space}/proposals`;
    const response = await fetch(url);
    const json = await response.json();
    const validIds = Object.keys(json).filter(
      (id) => !this._proposalsToFilter.includes(id),
    );
    const proposals = await Promise.all(
      validIds.map(async (id) => {
        const proposal = new SnapshotProposal(this, json[id]);
        await proposal.load();
        return proposal;
      }),
    );
    return proposals.filter((proposal) => proposal.isValid);
  }

  async getVotes(proposal) {
    const url = `${this.url}/${this.space}/proposal/${proposal.id}`;
    const response = await fetch(url);
    const json = await response.json();
    return Object.values(json).map(
      (vote) => new SnapshotVote(this, proposal, vote),
    );
  }

  async load() {
    this._proposals = await this.getProposals();
    this.touch();
  }

  subscribe(callback) {
    callback(this);
    this._callback = (obj) => {
      callback(obj);
    };
    return () => {
      this._callback = undefined;
    };
  }

  touch() {
    this._callback && this._callback(this);
  }
}

export const snapshotAPI = new SnapshotAPI(
  ApiUrl,
  'elasticdao.eth',
  ProposalsToFilter,
);

export const publish = async (proposal) => {
  const url = `${ApiUrl}/message`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(proposal),
  });

  return response.json();
};
