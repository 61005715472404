<script>
import { organization } from '../stores/organization.js';
import {
  changeDashboardTab,
  changeMembershipTab,
  MembershipTabs,
} from '../routes/index.js';

import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import Join from '../components/client/organizations/Join.svelte';
import JoinSelect from '../components/client/organizations/JoinSelect.svelte';
import Exit from '../components/client/organizations/Exit.svelte';

export let params = {};
</script>

<Header params="{params}" />

<div class="membership">
  <div class="container is-max-desktop">
    <div class="tabs is-max-desktop">
      <ul>
        <li>
          <button
            on:click="{() => changeDashboardTab(params.id, 'home')}"
          >Home</button>
        </li>
        <li
          class="{(!params.tab || params.tab === MembershipTabs.Join || params.tab === MembershipTabs.JoinSelect) && 'is-active'}"
        >
          <button
            on:click="{() => changeMembershipTab(params.id, MembershipTabs.Join)}"
          >Join</button>
        </li>

        <li class="{params.tab === MembershipTabs.Exit && 'is-active'}">
          <button
            on:click="{() => changeMembershipTab(params.id, MembershipTabs.Exit)}"
          >Exit</button>
        </li>
      </ul>
    </div>

    <div class="tabs-content">
      {#if !params.tab || params.tab === MembershipTabs.JoinSelect}
        <JoinSelect params="{params}" />
      {/if}
      {#if !params.tab || params.tab === MembershipTabs.Join}
        <Join params="{params}" />
      {/if}
      {#if params.tab === MembershipTabs.Exit}
        <Exit params="{params}" />
      {/if}
    </div>
  </div>
</div>

<Footer params="{params}" />
