<script>
import { _ } from 'svelte-i18n';

export let params = {};

let logo = 'images/edao.svg';
</script>

<footer>
  <div class="container is-max-desktop">
    <div class="columns has-text-centered">
      <div class="column is-mobile">
        <div class="social-icons">
          <a href="https://twitter.com/elasticdao" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="https://github.com/elasticdao" target="_blank">
            <i class="fab fa-github"></i>
          </a>
          <a href="https://forum.elasticdao.org/" target="_blank">
            <i class="fab fa-discourse"></i>
          </a>
          <a href="https://discord.gg/JhsCVAf" target="_blank">
            <i class="fab fa-discord"></i>
          </a>
          <a href="https://medium.com/elasticdao" target="_blank">
            <i class="fab fa-medium"></i>
          </a>
          <a href="https://docs.elasticdao.org">
            <i class="fas fa-book"></i>
          </a>
        </div>

        <div class="column is-full">
          <p>
            Built by ElasticDAO, Powered by
            <a href="https://ethereum.org" target="_blank"><i
                class="fab fa-ethereum"
              ></i>
              Ethereum</a>
            and
            <a href="https://ipfs.io" target="_blank">IPFS</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
