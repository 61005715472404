import { isAddress } from '@pie-dao/utils';
import BigNumber from 'bignumber.js';
import snapshot from '@snapshot-labs/snapshot.js';
import { ipfsSnapshot } from './snapshotData';

export default class SnapshotProposal {
  constructor(api, raw) {
    this._api = api;
    this._data = {};
    this._raw = raw;
    this._scores = {};
    this._votes = {};
  }

  get abstain() {
    return this.votes.reduce((acc, vote) => {
      if (vote.choice === this.choices[2]) {
        return acc.plus(vote.weight);
      }

      return acc;
    }, BigNumber(0));
  }

  get author() {
    return this._raw.address.toLowerCase();
  }

  get body() {
    return this._raw.msg.payload.body;
  }

  get choices() {
    return this._raw.msg.payload.choices;
  }

  get end() {
    return this._raw.msg.payload.end;
  }

  get id() {
    return this._raw.authorIpfsHash;
  }

  get isValid() {
    const { stats } = this._data;
    return stats && stats.eligibleVoteCreators.includes(this.author);
  }

  get name() {
    return this._raw.msg.payload.name;
  }

  get no() {
    return this.votes.reduce((acc, vote) => {
      if (vote.choice === this.choices[1]) {
        return acc.plus(vote.weight);
      }

      return acc;
    }, BigNumber(0));
  }

  get quorum() {
    const { stats } = this._data;

    if (stats) {
      const votableTokens = BigNumber(stats.quorum);
      return this.voted.dividedBy(votableTokens).multipliedBy(100);
    }

    return BigNumber(0);
  }

  get snapshot() {
    return this._raw.msg.payload.snapshot;
  }

  get start() {
    return this._raw.msg.payload.start;
  }

  get status() {
    const now = Math.floor(Date.now() / 1000);
    let status = 'active';
    if (this.end < now) {
      status = 'closed';
    } else if (this.start > now) {
      status = 'pending';
    }
    return status;
  }

  get yes() {
    return this.votes.reduce((acc, vote) => {
      if (vote.choice === this.choices[0]) {
        return acc.plus(vote.weight);
      }

      return acc;
    }, BigNumber(0));
  }

  get voted() {
    return this.votes.reduce(
      (acc, vote) => acc.plus(vote.weight),
      BigNumber(0),
    );
  }

  get votes() {
    return this._votes;
  }

  getScore(address) {
    let score = BigNumber(this._scores[address] || 0);
    let compound = 0;
    if (this.id === 'QmTi8kgp7gyJNLMxVNCLtm4JLvkzCPsWuehPZEmL6ayxwB') {
      compound = 1;
    } else if (this.id === 'QmTD7mjbUFyFERjpSHejHJ58e4tEaPmDFvKk6nVckJ8Ce6') {
      compound = 2;
    } else if (this.id === 'QmQ7hyvFo4Gt7BRWGzyvRszHisC81bRF33xN5KPLF4LMi7') {
      compound = 3;
    }
    for (let i = 0; i < compound; i += 1) {
      score = score.multipliedBy(1.05);
    }
    if (score.isGreaterThan('368.8966681390180631')) {
      score = BigNumber('368.8966681390180631');
    }
    return score;
  }

  async getScores() {
    const snapshotData = await ipfsSnapshot(this.snapshot, false);
    const scores = {};

    if (snapshotData) {
      const addresses = Object.keys(snapshotData.balances);
      for (let i = 0; i < addresses.length; i += 1) {
        scores[addresses[i]] =
          snapshotData.balances[addresses[i]].balanceOfVoting;
      }
    } else {
      const rawScores = (
        await snapshot.utils.getScores(
          this._api.space,
          this._raw.msg.payload.metadata.strategies,
          '1',
          snapshot.utils.getProvider('1'),
          this.votes.map(({ voter }) => voter),
          this.snapshot,
        )
      )[0];

      const addresses = Object.keys(rawScores);
      for (let i = 0; i < addresses.length; i += 1) {
        scores[addresses[i].toLowerCase()] = `${rawScores[addresses[i]]}`;
      }
    }

    this._scores = scores;
    this._api.touch();
  }

  async getVotes() {
    this._votes = await this._api.getVotes(this);
    await this.getScores();
    return this.votes;
  }

  async load() {
    this._data = await ipfsSnapshot(this.snapshot);
    console.log(this.name, 'block index', this._data.block);

    if (this.isValid) {
      await this.getVotes();
    }
  }

  didVote(address) {
    return !!this.myVote(address);
  }

  myVote(address) {
    const match = `${address}`.toLowerCase();
    if (!isAddress(match)) {
      return false;
    }
    return this._votes.find((v) => v.voter === match);
  }
}
