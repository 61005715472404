export const DefaultTab = 'home';

export const DashboardTabs = {
  Finances: 'finances',
  Home: DefaultTab,
  Modules: 'modules',
  Join: 'join',
  Exit: 'exit',
};

export const ModuleMappings = {
  'snapshot-voting': 'SnapshotVoteModule',
};

export const OrganizationSettingsTabs = {
  DAO: 'dao',
  Summoning: 'summoning',
};

export const MembershipTabs = {
  Join: 'join',
  JoinSelect: 'join-select',
  Exit: 'exit',
};

export const Paths = {
  Client: 'browse',
  Create: 'create',
  Dashboard: 'dashboard',
  Modules: 'modules',
  NewVote: 'new-vote',
  NotFound: '404',
  OrganizationSettings: 'settings',
  Membership: 'membership',
};

export const SubTypes = {
  Core: 'core',
  Module: 'module',
};

export const VoteFilters = {
  Active: 'active',
  All: 'all',
  Closed: 'closed',
  Pending: 'pending',
  Financial: 'financial',
  Informational: 'informational',
  Permissions: 'permissions',
  Transactional: 'transactional',
};
