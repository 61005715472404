/* eslint no-await-in-loop: 0 */

import { ElasticDAO, utils } from '@elastic-dao/sdk';

import sdk from '../config/sdk';

const { toEthersBigNumber } = utils;

export default class GnosisMultisig {
  constructor(api, raw) {
    this._api = api;
    this._raw = raw;
    console.log('api', this.api);
  }

  get address() {
    return this._raw.address;
  }

  get api() {
    return this._api;
  }

  get nonce() {
    return this._raw.nonce;
  }

  get owners() {
    return this._raw.owners;
  }

  /*
  {
    "to": "0xAaa1f5FC9617195B5Aa7FD1bD989D47f9E8D3f82",
    "value": "0",
    "data": "",
    "operation": 0,
    "nonce": 16,
    "safeTxGas": 10469480,
    "baseGas": 0,
    "gasPrice": "0",
    "gasToken": "0x0000000000000000000000000000000000000000",
    "refundReceiver": "0x0000000000000000000000000000000000000000",
    "contractTransactionHash": "0x5732a0afd3c4b0d659e1d702f26f35cc829d5cddf17220d891f9b964b70f4aa2",
    "transactionHash": null,
    "sender": "0x434DeD09939b64CD76BAA81f9A394283D4C71F05",
    "origin": null,
    "signature": ""
  }
  */
  async buildRewardTransactions(rewardData) {
    const addresses = Object.keys(rewardData);
    const transactions = [];
    const contract = ElasticDAO.contract(sdk, this.api.daoAddress);
    console.log('contract', this.api.daoAddress, sdk, contract);

    const transactionsData = addresses.reduce(
      ([addressSets, rewards], address) => {
        let lastSetIndex = addressSets.length - 1;
        if (lastSetIndex < 0) {
          lastSetIndex = 0;
        }

        console.log(
          'addressSets',
          addressSets,
          'rewards',
          rewards,
          'lastSetIndex',
          lastSetIndex,
          'address',
          address,
        );

        if (
          addressSets[lastSetIndex] &&
          addressSets[lastSetIndex].length > 0 &&
          addressSets[lastSetIndex].length % 60 === 0
        ) {
          addressSets.push([]);
          rewards.push([]);
          lastSetIndex += 1;
        }

        addressSets[lastSetIndex].push(address);
        rewards[lastSetIndex].push(toEthersBigNumber(rewardData[address], 18));

        return [addressSets, rewards];
      },
      [[[]], [[]]],
    );

    for (let i = 0; i < transactionsData[0].length; i += 1) {
      const rewardTransaction = await contract.populateTransaction.reward(
        transactionsData[0][i],
        transactionsData[1][i],
      );
      transactions.push(rewardTransaction.data);
    }

    window.gnosisTransactions = transactions;
    console.log('transactions', transactions);
  }
}
