/* eslint no-undef: 0 */
/* eslint import/prefer-default-export: 0 */

import { edaoTestnet, kovan, localhost, mainnet, ropsten } from './networks';

export const conditionalEnv = () => {
  let env = localhost;

  // ENV_NETWORK is replace by rollup, so can be safely ignored by eslint
  // eslint-disable-next-line no-undef
  if (ENV_NETWORK === 'edaoTestnet') {
    env = edaoTestnet;
  } else if (ENV_NETWORK === 'kovan') {
    env = kovan;
  } else if (ENV_NETWORK === 'mainnet') {
    env = mainnet;
  } else if (ENV_NETWORK === 'ropsten') {
    env = ropsten;
  }

  return env;
};
