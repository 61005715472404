export const edaoTestnet = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 1,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x7594d6F31F685DcCcE8C00FCe91a2071Edd42524',
};

export const localhost = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 1,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
};

export const kovan = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 42,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x7a4cdBAe8ce3cfFB89fF3c77bF26A98b01a38931',
};

export const mainnet = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 1,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x3c919d30286c6407C1E59D9fc05B45Db81E6257c',
};

export const ropsten = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 3,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x08b6581F3A3315D3Bec69f01A9Fd7E497CCf398B',
};
