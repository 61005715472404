<script>
import { ethers } from 'ethers';
import { get } from 'svelte/store';
import { PieProxyABI } from '@elastic-dao/sdk';
import BigNumber from 'bignumber.js';

import { balances } from '../stores/balances.js';
import { eth } from '../subeth';
import { gnosisSafeAPI } from '../multisig';
import { liveOrganizations } from '../stores/organizations.js';
import { snapshotAPI } from '../snapshot';
import sdk from '../config/sdk.js';

window.BigNumber = BigNumber;
window.ethers = ethers;
window.get = get;
window.gnosisSafeAPI = gnosisSafeAPI;
window.liveOrganizations = liveOrganizations;
window.PieProxyABI = PieProxyABI;
window.sdk = sdk;
window.snapshotAPI = snapshotAPI;

const { keys } = liveOrganizations;

$: window.balances = $balances;
$: window.eth = $eth;
$: window.keys = $keys;
</script>

<!-- debugging mode enabled -->
