<script>
import Dashboard from './Dashboard.svelte';
import NewVote from './NewVote.svelte';
import Reward from './Reward.svelte';

export let sdk;
export let params = {};
</script>

{#if params.action === 'new'}
  <NewVote params="{params}" sdk="{sdk}" />
{:else if params.action === 'reward'}
  <Reward params="{params}" sdk="{sdk}" />
{:else}  
  <Dashboard params="{params}" sdk="{sdk}" />
{/if}

