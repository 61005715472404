<script>
import BigNumber from 'bignumber.js';
import { _ } from 'svelte-i18n';
import { capitalDelta, t } from '@elastic-dao/sdk';

import { amountFormatter } from '../../../utils.js';
import { balances, key } from '../../../stores/balances.js';
import { changeMembershipTab } from '../../../routes/push.js';
import { liveOrganizations } from '../../../stores/organizations.js';
import { MembershipTabs } from '../../../routes/constants.js';
import { oracle } from '../../../stores/oracle.js';
import AddressBar from '../../shared/AddressBar.svelte';
import Card from '../../shared/Card.svelte';

import { ethers } from 'ethers';

export let params = {};

const { account, keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];

const daoBalances = (balances) =>
  balances.filter(({ address }) =>
    [ethers.constants.AddressZero, $token.uuid]
      .map((address) => address.toLowerCase())
      .includes(address.toLowerCase()),
  );

const etherscanHref = (address) => {
  if (address === ethers.constants.AddressZero) {
    return `https://etherscan.io/address/${$account.address}`;
  }
  return `https://etherscan.io/token/${address}?a=${$account.address}`;
};

const addEgtToMetamask = async () => {
  const tokenAddress = '0x2aa5ce395b00cc486159adbdd97c55b535cf2cf9';
  const tokenSymbol = 'EGT';
  const tokenDecimals = 18;
  const tokenImage =
    'https://www.cloudflare-ipfs.com/ipfs/QmQV4Ut54i5ixPS8335jZ9prtQGjjxwEYyQ6HSzLGarMNb/300ppi_512512-01.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

$: daoETHBalance = $balances[key($dao.uuid)] || BigNumber(0);
$: dollarValue = daoETHBalance.multipliedBy($oracle.eth);
$: tValue = $token.lambda ? t($token.lambda, $token.m, $token.k) : BigNumber(0);
$: delta = capitalDelta(daoETHBalance, tValue);
$: tDollarValue = delta.multipliedBy($oracle.eth);
</script>

<div class="tokens fade-in">
  <section class="section">
    <div class="section-header">
      <h1>{$_('Tokens.Tokens')}</h1>

      <h1 class="add-to-metamask" on:click="{() => addEgtToMetamask()}">
        <img class="metamask" src="images/metamask.svg" alt="metamask" />
        Add EGT to Metamask
      </h1>

      <button
        on:click="{() => changeMembershipTab(params.id, MembershipTabs.Join)}"
        class="button action-button"
      >
        <i class="fas fa-coins mr-3"></i>{$_('Tokens.mint_tokens')}
      </button>
    </div>

    <div class="columns is-multiline">
      <div class="column is-5 is-mobile">
        <Card
          cardTitle="{`${$token.name} (${$token.symbol})`}"
          identicon="{false}"
          noZoom="{true}"
        >
          <a
            href="{`https://etherscan.com/address/${$token.uuid}`}"
            target="_blank"
            rel="nofollow noopener"
          >
            <AddressBar address="{$token.uuid}" />
          </a>
        </Card>
      </div>

      <div class="column is-7 is-mobile">
        <Card cardTitle="" identicon="{false}" noZoom="{true}">
          <div class="card-data">
            <div class="mr-5">
              <h3>{$_('Tokens.Holders')}</h3>
              <p>
                {amountFormatter({
                  amount: $token.numberOfTokenHolders,
                  decimalPlaces: 0,
                  decimalShift: 18,
                })}
              </p>
            </div>

            <div class="mr-5">
              <h3>Exit Value</h3>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
              >
                <p class="mr-3">
                  {amountFormatter({ amount: delta })}
                  <span class="token-symbol">ETH</span>
                </p>
                <p>
                  {amountFormatter({ amount: tDollarValue, decimalPlaces: 2 })}
                  <span class="token-symbol">USD</span>
                </p>
              </div>
            </div>

            <div>
              <h3>{$_('Tokens.Total_Supply')}</h3>
              <p>
                {amountFormatter({ amount: tValue })}
                <span class="token-symbol">{$token.symbol}</span>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </section>
</div>

<div class="finance fade-in">
  <section class="section">
    <div class="section-header">
      <h1>{$_('Finance.Balances')}</h1>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th><abbr title="Token">{$_('Finance.Token')}</abbr></th>
          <th><abbr title="Balance">{$_('Finance.Balance')}</abbr></th>
          <th><abbr title="Value">{$_('Finance.Value')}</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ether</td>
          <td>{amountFormatter({ amount: daoETHBalance })} ETH</td>
          <td>
            {amountFormatter({ amount: dollarValue, decimalPlaces: 2 })}
            USD
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  {#if $account.balances.length > 0}
    <section class="section">
      <div class="section-header">
        <h1>{$_('Finance.Your_Balances')}</h1>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th><abbr title="Token">{$_('Finance.Token')}</abbr></th>
            <th><abbr title="Balance">{$_('Finance.Balance')}</abbr></th>
            <th colspan="2">
              <abbr title="Value">{$_('Finance.Value')}</abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          {#each daoBalances($account.balances) as balance}
            <tr>
              <td>
                <a
                  href="{etherscanHref(balance.address)}"
                  alt="{balance.name}"
                  target="_blank"
                >{balance.name}</a>
              </td>
              <td>
                {amountFormatter({ amount: balance.amount })}
                {balance.symbol}
              </td>
              <td>
                {amountFormatter({
                  amount: balance.valueUSD,
                  decimalPlaces: 2,
                })}
                USD
              </td>
              <td>{amountFormatter({ amount: balance.valueETH })} ETH</td>
            </tr>
          {/each}
        </tbody>
      </table>
    </section>
  {/if}

  <!-- NOTE: Waiting on archive node integration
  <section class="section">
    <div class="section-header">
      <h1>{$_('Finance.Transfers')}</h1>

      <button class="button action-button">
        <i class="fas fa-file-export mr-3"></i>{$_('Finance.export')}
      </button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th><abbr title="Date">{$_('Finance.Date')}</abbr></th>
          <th><abbr title="To/From">{$_('Finance.To/From')}</abbr></th>
          <th><abbr title="Amount">{$_('Finance.Amount')}</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2020-10-7</td>
          <td>0x15d...5d4fbv</td>
          <td>+0.0001 ETH</td>
        </tr>
      </tbody>
    </table>
  </section>
  -->
</div>
